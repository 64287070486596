* {
  font-family: "Outfit", sans-serif;
  margin: 0;
  padding: 0;
}
.banner_bg {
  width: 100%;
  background: linear-gradient(
    113deg,
    rgba(255, 104, 32, 0.9) -1.21%,
    rgba(12, 10, 87, 0.9) 52.28%,
    rgba(4, 106, 56, 0.9) 87.35%
  );
  padding: 0 0 3em;
  display: block;
}
.banner_bg img {
  width: auto;
  height: auto;
  max-width: 100%;
  display: block;
}
.banner_bg h1 {
  font-size: 60px;
  font-weight: bold;
  color: #fff;
  padding-top: 0.5em;
  display: block;
}
.banner_bg h1 span {
  font-size: 40px;
  font-weight: 300;
  color: #fff;
  display: block;
}
.navbar {
  background: linear-gradient(
    144deg,
    rgb(238 112 59) 6.79%,
    rgb(35 38 89) 63.28%,
    rgb(29 111 79) 99.35%
  );
  padding: 0.5rem 1rem;
  position: fixed !important;
}
.nav_ralign {
  justify-content: end;
}
.nav a {
  color: #ffffff;
}
.nav a:hover {
  color: #ff6820;
}
.nav a.active {
  color: #ff6820;
}
.btn_signin {
  border: #fff 2px solid;
  border-radius: 50px;
  padding: 0.5em 1.5em;
  color: #fff;
}

.ods_pd0 {
  padding: 0;
}

.bg_gray {
  width: 100%;
  height: auto;
  background: #f4f4fe;
  padding: 4em 0;
  display: block;
}
.bg_drackgray {
  width: 100%;
  height: auto;
  background: #e9e9f6;
  padding: 4em 0;
  display: block;
}
.bg_white {
  width: 100%;
  height: auto;
  background: #ffffff;
  padding: 4em 0;
  display: block;
}
.bg_222 {
  width: 100%;
  height: auto;
  background: #222222;
  padding: 2em 0;
  display: block;
}

.pt-3 {
  padding-top: 3em;
}
.pt-4 {
  padding-top: 4em;
}
.pt-5 {
  padding-top: 5em;
}
.pt-6 {
  padding-top: 6em;
}
.pt-7 {
  padding-top: 7em;
}
.pt-8 {
  padding-top: 8em;
}
.pt-9 {
  padding-top: 9em;
}

.mySlides {
  display: none;
}
img {
  vertical-align: middle;
}
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}
.dot {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: #fff;
  border-radius: 10px;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.active {
  background-color: #ff6820;
  width: 20px;
}
.fade {
  animation-name: fade;
  animation-duration: 2.5s;
}
@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
@media only screen and (max-width: 300px) {
  .text {
    font-size: 11px;
  }
}

.slide-v {
  width: 100%;
  margin: 0 auto;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.slide {
  padding: 0;
  color: white;
  text-align: right;
  font-size: 16px;
}
.slide img {
  width: auto;
  height: auto;
  max-width: 100%;
  border: none;
  display: block;
}
.slide p {
  font-size: 15px;
  color: #fff;
  text-align: left;
  padding: 0.5em 0 0;
  margin: 0;
  display: block;
}
.slide span {
  font-size: 12px;
  color: #fff;
  text-align: left;
  padding: 0;
  display: block;
}
.slide span i {
  padding-right: 3px;
}

.button-container {
  padding-top: 0;
  width: 100%;
  margin: -2.4em auto 0;
  border: none;
  position: relative;
  z-index: 99;
}
.button-container button {
  background: #fff;
  border: none;
  padding: 2px 8px;
  margin-left: 5px;
  float: right;
}

.mc_main {
  display: block;
}
.mc_main h2 {
  font-size: 42px;
  font-weight: 600;
  color: #000000;
  border-left: #ff6820 5px solid;
  padding: 0 0 0 0.3em;
  display: block;
}
.search_box_01 {
  background: #ffffff;
  padding: 0.5em 1em;
  float: right;
}
.search_box_01 i {
  margin: 4px 0;
  color: #222222;
  z-index: 99;
  position: relative;
  float: right;
}
.search_box_01 input {
  background: none;
  border: none;
  outline: none;
  font-size: 16px;
  margin-right: 5px;
  color: #222222;
  float: right;
}

.mc_card {
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  min-height: 325px;
  display: block;
}
.mc_card img {
  width: auto;
  height: auto;
  max-width: 100%;
  border: none;
  filter: grayscale(100%);
  display: block;
}
.mc_card span {
  width: 110px;
  margin: -2.2em 0 0;
  position: relative;
  z-index: 9;
  font-size: 14px;
  color: #ffffff;
  background: #ff6820;
  padding: 5px 10px;
  text-align: center;
  float: left;
}
.mc_card p {
  font-size: 14px;
  color: #000000;
  padding: 0.8em;
  margin: 0;
  display: block;
}
.mc_card p b {
  font-size: 16px;
  color: #000000;
  font-weight: bold;
  padding-bottom: 0.3em;
  display: block;
}
.mc_card p a {
  font-size: 14px;
  color: #ff6820;
  font-weight: 500;
  text-decoration: none;
  text-align: right;
  padding-top: 1em;
  float: right;
}
.btn_seeall {
  width: 150px;
  border: #000000 2px solid;
  background-color: #fff !important ;
  border-radius: 50px;
  text-align: center;
  font-weight: 500;
  color: #000000;
  font-size: 16px;
  text-decoration: none;
  padding: 0.5em 1.5em;
  margin: 3em auto 0;
  display: block;
}
.btn_seeall i {
  color: #ff6820;
  padding-left: 2em;
}

.btn_post {
  width: 130px;
  background: #222222;
  border: none;
  border-radius: 50px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  padding: 0.5em 1.5em;
  margin: 0;
  float: right;
}
.btn_post i {
  color: #fff;
  padding-left: 1em;
}

.scroll-container {
  display: flex;
  align-items: center;
  position: relative;
}
.scrol_container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  white-space: nowrap;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.scrol_container::-webkit-scrollbar {
  display: none;
}
.ad_card {
  flex: 0 0 auto;
  margin-right: 20px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;
  position: relative;
  overflow: hidden;
}
.ad_card:hover {
  transform: scale(1.05);
}
.scroll-btn {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
}
.scroll-btn.left {
  left: 0;
}
.scroll-btn.right {
  right: 0;
}

.event_scroll {
  display: -webkit-inline-box;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 20px;
  
  border-radius: 10px;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.event_scroll::-webkit-scrollbar {
  display: none;
}

.event_autoscroll{
animation: scroll 20s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0); /* Start at the top */
  }
  100% {
    transform: translateX(-100%); /* Move to the top by the height of the container */
  }
}

.event_main {
  display: block;
}
.event_main h2 {
  font-size: 42px;
  font-weight: 600;
  color: #000000;
  border-left: #ff6820 5px solid;
  padding: 0 0 0 0.3em;
  display: block;
}
.event_main img {
  width: auto;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

.event_card {
  background: #f4f4fe;
  padding-bottom: 1em;
  transition: transform 0.2s;
  display: block;
}
.event_card img {
  width: auto;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
.event_card span {
  font-size: 12px;
  color: #999;
  text-align: left;
  font-weight: normal;
  padding: 1em 1em 0;
  display: block;
}
.event_card span i {
  padding-right: 5px;
}
.event_card p {
  font-size: 16px;
  color: #000;
  text-align: left;
  font-weight: normal;
  padding: 0 1em;
  margin: 0;
  display: block;
}
.event_card p b {
  font-size: 16px;
  color: #000;
  text-align: left;
  font-weight: bold;
  padding: 0.5em 0 0;
  display: block;
}
.event_card a {
  font-size: 16px;
  color: #ff6820;
  text-align: right;
  font-weight: normal;
  padding: 0 1em;
  clear: both;
  display: block;
}
.event_card:hover {
  transform: scale(1.1);
}

.job_card {
  background: #ffffff url(./assets/images/jobcard_bg.png) no-repeat bottom left;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  padding-bottom: 0.6em;
  display: block;
}
.jobs_main ul li {
  list-style-type: none;
}
.job_card label {
  font-size: 12px;
  color: #999;
  text-align: left;
  font-weight: normal;
  padding: 1em 1em 0;
  display: block;
}
.job_card label i {
  padding-right: 5px;
}
.job_card p {
  font-size: 13px;
  color: #000;
  text-align: left;
  font-weight: normal;
  padding: 0 1em;
  margin: 0;
  display: block;
}
.job_card a {
  font-size: 13px;
  color: #ff6820;
  text-align: right;
  font-weight: normal;
  padding: 0 1em;
  clear: both;
  display: block;
}
.jobs_main h4 {
  font-size: 42px;
  color: #ff6820;
  font-weight: bold;
  padding-bottom: 1em;
  display: block;
}
.jobs_main h4 span {
  font-size: 26px;
  color: #222222;
  font-weight: normal;
  display: block;
}
.jobs_main h2 {
  font-size: 42px;
  font-weight: 600;
  color: #000000;
  border-left: #ff6820 5px solid;
  padding: 0 0 0 0.3em;
  display: block;
}

.doctors_main {
  display: block;
}
.doctors_main h2 {
  font-size: 42px;
  font-weight: 600;
  color: #000000;
  border-left: #ff6820 5px solid;
  padding: 0 0 0 0.3em;
  display: block;
}
.doctor_photo {
  display: block;
  margin: 0 0 1.5em;
}
.doctor_photo h5 {
  position: absolute;
  bottom: 0.5em;
  right: 0.5em;
  background: #fff;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 0.3em 0.8em;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  display: block;
}
.doctor_photo h5 span {
  font-size: 12px;
  color: #ff6820;
  font-weight: normal;
  display: block;
}
.doctor_photo img {
  width: auto;
  height: auto;
  max-width: 100%;
  border: none;
  margin: 0 auto;
  display: block;
}

.prime_listings h2 {
  font-size: 42px;
  font-weight: 600;
  color: #000000;
  border-left: #ff6820 5px solid;
  padding: 0 0 0 0.3em;
  display: block;
}
.prime_star {
  width: auto;
  height: auto;
  max-width: 100%;
  position: absolute;
  z-index: 99;
  top: 0;
  right: 1.5em;
  float: right;
}

.prime_card {
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  min-height: 325px;
  display: block;
}
.prime_card img {
  width: auto;
  height: auto;
  max-width: 100%;
  border: none;
  display: block;
}
.prime_card span {
  width: 110px;
  margin: -2.2em 0 0;
  position: relative;
  z-index: 9;
  font-size: 14px;
  color: #ffffff;
  background: #ff6820;
  padding: 5px 10px;
  text-align: center;
  float: left;
}
.prime_card p {
  font-size: 14px;
  color: #767e94;
  padding: 0.8em;
  margin: 0;
  display: block;
}
.prime_card p b {
  font-size: 16px;
  color: #191f33;
  font-weight: bold;
  padding-bottom: 0.3em;
  display: block;
}
.prime_card a {
  font-size: 14px;
  color: #ff6820;
  font-weight: 500;
  text-decoration: none;
  text-align: right;
  padding: 0.8em;
  float: right;
}
.prime_card label {
  font-size: 12px;
  color: #999;
  text-align: left;
  padding: 0.8em;
  display: block;
}
.prime_card label i {
  padding-right: 3px;
}

.classifieds_main h2 {
  font-size: 42px;
  font-weight: 600;
  color: #000000;
  border-left: #ff6820 5px solid;
  padding: 0 0 0 0.3em;
  display: block;
}
.classfi_icon {
  width: 100%;
  height: auto;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  padding: 1em;
  margin: 0 0 1.8em;
  display: block;
}
.classfi_icon:hover {
  transform: scale(1.1);
}
.classfi_icon span {
  width: 100%;
  font-size: 16px;
  text-decoration: none;
  color: #fff;
  text-align: center;
  display: block;
}
.classfi_icon img {
  width: auto;
  height: auto;
  max-width: 100%;
  border: none;
  margin: 0 auto 1em;
  display: block;
}
.cf_icon1 {
  background: #167d6c;
}
.cf_icon2 {
  background: #023f80;
}
.cf_icon3 {
  background: #d8209a;
}
.cf_icon4 {
  background: #78496b;
}
.cf_icon5 {
  background: #9668e4;
}
.cf_icon6 {
  background: #f78026;
}
.cf_icon7 {
  background: #77a22f;
}
.cf_icon8 {
  background: #9d7e4f;
}
.cf_icon9 {
  background: #f48886;
}
.cf_icon10 {
  background: #454545;
}
.cf_icon11 {
  background: #3572dd;
}
.cf_icon22 {
  background: #f1ba46;
}
.cf_icon22 span {
  color: #000 !important;
}

.ad_card1 {
  margin-right: 20px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;
  display: inline-block;
}
.ad_card1:hover {
  transform: scale(1.05);
}

.podcast_main {
  display: block;
}
.podcast_main h2 {
  font-size: 42px;
  font-weight: 600;
  color: #000000;
  text-align: center;
  padding: 0 0 0 0.3em;
  display: block;
}
.podcast_main h2::after {
  width: 50px;
  border-bottom: #ff6820 5px solid;
  content: "";
  margin: 0.2em auto;
  display: block;
}

.podcast_card {
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  padding-bottom: 1em;
  transition: transform 0.2s;
  display: block;
}
.podcast_card iframe {
  width: 100%;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
.podcast_card label {
  background: #ff6820;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  position: relative;
  z-index: 99;
  margin-top: -1.9em;
  padding: 0.2em 1em;
  float: left;
}
.podcast_card span {
  font-size: 12px;
  color: #999;
  text-align: left;
  font-weight: normal;
  padding: 1em 1em 0;
  display: block;
}
.podcast_card span i {
  padding-right: 5px;
}
.podcast_card p {
  font-size: 16px;
  color: #000;
  text-align: left;
  font-weight: normal;
  padding: 0 1em;
  margin: 0;
  display: block;
}
.podcast_card p b {
  font-size: 16px;
  color: #000;
  text-align: left;
  font-weight: bold;
  padding: 0.5em 0 0;
  display: block;
}

.podcast_scroll {
  display: -webkit-inline-box;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.podcast_scroll::-webkit-scrollbar {
  display: none;
}

.marquee-wrapper {
  text-align: center;
  overflow-x: hidden;
}
.marquee-wrapper .container {
  overflow: hidden;
}
.marquee-inner span {
  float: left;
  width: 50%;
}
.marquee-wrapper .marquee-block {
  --total-marquee-items: 5;
  width: 100%;
  height: 130px;
  width: calc(300px * (var(--total-marquee-items)));
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}
.marquee-inner {
  display: block;
  width: 300%;
  position: absolute;
}
.marquee-inner.to-left {
  animation: marqueeLeft 25s linear infinite;
}
.marquee-inner.to-right {
  animation: marqueeRight 25s linear infinite;
}
.ad_card {
  display: inline-block;
  float: left;
  transition: all 0.2s ease-out;
}
@keyframes marqueeLeft {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@keyframes marqueeRight {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}

section#slider {
  width: 100%;
}

section#slider span.nowrap {
  white-space: nowrap;
}

section#slider .slider-wrapper {
  padding: 0;
}

section#slider .scroll-wrapper {
  width: 100%;
  overflow-x: hidden;
  min-height: 195px;
}

section#slider ul {
  padding: 0;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 0;
}

section#slider li.slider {
  display: inline-block;
  position: relative;
}

section#slider span.slider-name,
span.slider-title {
  display: block;
  position: absolute;
  color: #fff;
  left: 2vw;
}

section#slider span.slider-name {
  font-weight: 900;
  bottom: 4vw;
  font-size: 1.5vw;
}

section#slider span.slider-title {
  font-weight: 100;
  bottom: 2.5vw;
  font-size: 1.3vw;
}

section#slider .more-slider {
  padding: 1vh 5% 0;
}

section#slider .more-slider h4 {
  font-family: "Playfair Display", serif;
  color: #5896b0;
  font-weight: 500;
  font-size: 3vw;
  padding: 35px 0 25px 0px;
  margin-bottom: 0;
  margin-top: 8px;
}

section#slider .more-slider p {
  padding-bottom: 2vh;
}

section#slider .slide-controls {
  color: #000;
  font-weight: 300;
}

section#slider .slide-controls span.slider-previous {
  margin-right: 15px;
  cursor: pointer;
}
section#slider .slide-controls span.slider-next {
  cursor: pointer;
}

section#slider .slide-controls i {
  font-size: 16px;
  background: #fff;
  padding: 0.5em 0.8em;
}

section#slider .more-slider a.button {
  border: 1px solid #5896b0;
  max-width: 200px;
  color: #5896b0;
  margin-top: 6vw;
}

section#slider .more-slider a.button:hover {
  color: #fff;
}

section#slider .more-slider a.button:before {
  background: #5896b0;
}

/*** Media Queries ***/

@media (max-width: 991px) {
  section#slider .more-slider {
    padding: 1vh 3% 0;
  }

  section#slider .more-slider h4 {
    font-size: 5vw;
    padding: 0 0 15px 0;
  }

  section#slider .slider-wrapper {
    width: 50%;
  }

  section#slider .content {
    padding: 35px;
  }

  section#slider .more-slider a.button {
    margin-top: 4vw;
    max-width: 175px;
    font-size: 15px;
    padding: 5px 0;
  }
}

@media (max-width: 768px) {
  section#slider .area {
    padding-right: 0;
    padding-left: 0;
  }

  section#slider .slider-wrapper {
    width: 100%;
  }

  section#slider .scroll-wrapper {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }

  section#slider li.slider {
    width: 49.2vw;
  }

  section#slider span.slider-title {
    bottom: 3.5vw;
    font-size: 3.3vw;
  }

  section#slider span.slider-name {
    bottom: 7vw;
    font-size: 3.5vw;
  }

  section#slider .more-slider h4 br,
  section#slider .slide-controls {
    display: none;
  }

  section#slider .more-slider a.button {
    margin-top: 0;
  }

  section#slider {
    padding-bottom: 0;
    margin-left: 0;
  }

  section#slider .more-slider {
    padding-left: 0;
  }

  section#slider .more-slider h4 {
    font-size: 7vw;
  }
}

footer {
  width: 100%;
  height: auto;
  background: rgba(22, 22, 22, 0.9);
  padding: 2em 0;
  display: block;
}
footer h2 {
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  border-left: #ff6820 5px solid;
  padding: 0 0 0 0.3em;
  margin-bottom: 1em;
  display: block;
}
footer ul {
  width: 48%;
  padding: 0;
  list-style-type: none;
  display: inline-grid;
}
footer ul li {
  font-size: 16px;
  list-style-type: none;
  display: block;
}
footer ul li a {
  text-decoration: none;
  color: #fff;
  font-weight: normal;
  padding: 0.5em 0;
  display: block;
}
footer ul li a.active {
  color: #ff6820;
  background: none;
}
footer img {
  width: auto;
  height: auto;
  max-width: 100%;
  border: none;
  margin: 0 auto;
  display: block;
}
footer p {
  font-size: 16px;
  color: #ffffff;
  font-weight: normal;
  padding: 0;
  display: block;
}
.azflogo {
  width: auto;
  height: auto;
  max-width: 100%;
  border: none;
  margin: 0 0 1em;
  display: block;
}
footer input {
  width: 100%;
  background: #fff;
  border: none;
  outline: none;
  border-radius: 0;
  font-size: 14px;
  padding: 0.5em 1em 0.5em 2.2em;
  margin: 0 0 1em;
  display: block;
}
footer button {
  width: 100%;
  background: #ff6820;
  border: none;
  color: #ffffff;
  border-radius: 0;
  text-align: center;
  padding: 0.5em 0;
  display: block;
}
footer .test_field i {
  color: #999;
  position: absolute;
  z-index: 99;
  padding: 9.5px;
}

.copyright {
  background: #333333;
  padding: 1.5em 0;
  display: block;
  clear: both;
}
.copyright p {
  font-size: 17px;
  color: #fff;
  text-decoration: none;
  font-weight: 300;
  padding: 0.25em 0;
  margin: 0;
  display: inline-block;
}
.fs_icons {
  width: 245px;
  height: auto;
  margin: 0 auto;
  float: right;
}
.fs_icons i {
  font-size: 24px;
  color: #ffffff;
  padding: 10px 15px;
  display: inline-table;
}
.fs_icons i:hover {
  color: #ff6820;
}

@media only screen and (max-width: 480px) {
  .navbar-header {
    width: 100%;
    float: left;
  }
  .a_fr {
    margin: 5px 0;
    float: right;
  }
  .navbar-nav {
    background: #fff;
  }
  .nav a {
    color: #000000;
  }
  .nav-link {
    border-bottom: #00000038 1px solid;
  }
  .dropdown-toggle::after {
    float: right;
  }
  .navbar .dropdown-menu {
    width: 100%;
  }
  .navbar .dropdown-menu a {
    border-bottom: #d6d6d6 1px solid;
  }
  #custom-search {
    margin: 1em -30px 1em 0;
  }
  .banner_bg h1 {
    font-size: 26px;
    font-weight: bold;
    color: #fff;
    padding: 0.2em 0;
    display: block;
  }
  .banner_bg h1 span {
    font-size: 20px;
    font-weight: 300;
    color: #fff;
    display: block;
  }
  .ods_pd0 {
    padding: 0 15px;
  }
  .search_box_01 {
    width: 100%;
    margin-top: 0.5em;
  }
  .mc_main h2 {
    font-size: 26px;
  }
  .search_box_01 input {
    width: 92%;
  }
  .mc_card {
    margin-bottom: 1.5em;
    min-height: auto;
    float: left;
  }
  .mc_card p {
    font-size: 16px;
  }
  .mc_card p a {
    padding: 1em 0;
    font-size: 16px;
  }
  .job_card {
    margin: 0 0 1em;
  }
  .jobs_main label {
    font-size: 12px;
  }
  .jobs_main p {
    font-size: 16px;
  }
  .jobs_main a {
    font-size: 16px;
  }
  .jobs_main h4 {
    padding-bottom: 0em;
  }

  .prime_card {
    margin: 0 0 1.5em;
  }
  .prime_card p {
    font-size: 16px;
  }
  .prime_card p b {
    font-size: 18px;
  }
  footer .col-sm-3 {
    padding-top: 2em;
  }
  .copyright p {
    font-size: 18px;
    width: 100%;
    text-align: center;
  }
  .fs_icons {
    width: 220px;
    float: none;
    display: block;
  }
} /* Wrapper for the slider */
.scroll-wrapper {
  overflow: hidden;
  width: 100%;
  position: relative;
}

/* Unordered list inside the slider */
.scroll-wrapper ul {
  display: flex;
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  left: 0;
  transition: left 1s ease;
}

/* List items for job cards */
.scroll-wrapper ul li {
  flex-shrink: 0; /* Prevent shrinking */
  padding: 10px; /* Space between cards */
  box-sizing: border-box; /* Include padding in width */
}

/* Job card styling */
.job_card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100%; /* Ensures all cards have consistent height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px; /* For consistent layout */
}

/* Controls for the slider */
.slide-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.slide-controls span {
  cursor: pointer;
  font-size: 24px;
  color: #333;
  transition: color 0.3s ease;
}

.slide-controls span:hover {
  color: #007bff;
}

/* Responsive layout for different screen sizes */
@media (min-width: 1200px) {
  .scroll-wrapper ul li {
    width: 30%; /* Show 3 items on large screens */
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .scroll-wrapper ul li {
    width: 33.33%; /* Show 3 items on medium screens */
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .scroll-wrapper ul li {
    width: 50%; /* Show 2 items on tablets */
  }
}

@media (max-width: 767px) {
  .scroll-wrapper ul li {
    width: 100%; /* Show 1 item on smaller screens */
  }
}

/* Buttons for Posting and See All */
.btn_post,
.btn_seeall {
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 25px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.btn_post:hover,
.btn_seeall:hover {
  background-color: #ff6820;
  color: #000;
}

/* Heading for Jobs section */
.jobs_main h2 {
  font-size: 28px;
  font-weight: 700;
  color: #333;
}

.btn_post {
  float: right;
}

.area {
  padding-top: 20px;
}
