html {
  scroll-behavior: auto;
}
.binduz-er-hero-news-portal {
  display: block;
}
.binduz-er-hero-news-portal
  .binduz-er-news-portal-item
  .binduz-er-news-portal-item-wrapped {
  display: flex;
}
.binduz-er-hero-news-portal .binduz-er-news-portal-item:last-child {
  margin-left: 0px;
}
.binduz-er-hero-news-portal .binduz-er-news-portal-item:last-child {
  padding-left: 0;
}
.binduz-formated-date {
  margin-left: 4px;
}
/* line clumbs */
.line-clump-5 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}
.line-clump-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.line-clump-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.line-clump-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.binduz-er-trending-box .binduz-er-title .binduz-er-title::before {
  width: 260px;
}
.binduz-er-featured-slider-item {
  position: relative;
}
.binduz-er-main-posts-area .binduz-er-play {
  display: none;
}

.binduz-er-news-slider-item {
  position: relative;
}
.binduz-er-news-viewed-most-slide {
  position: relative;
}
.binduz-er-recently-viewed-box .binduz-er-play {
  display: none;
}
.binduz-er-news-slider-2-item .binduz-er-news-viewed-most {
  margin: 0 0;
  padding: 0 2px;
}
.binduz-er-featured-slider-2 {
  position: relative;
}
.binduz-er-top-header-area-4 .binduz-er-top-header-lang {
  background: #ff6821;
  border: 0;
  border-radius: 0;
  font-weight: 500;
  text-transform: capitalize;
  height: 60px;
  width: 140px;
  text-align: center !important;
  padding: 0;
  padding-top: 10px;
  margin-right: 50px;
}
.binduz-er-top-header-area-4 .binduz-er-top-header-lang button {
  color: #fff;
}
.binduz-er-top-header-area-4 .binduz-er-top-header-wrapper {
  display: flex;
}
.binduz-er-topbar-headline {
  position: relative;
}
.binduz-er-top-news-2-slider {
  position: relative;
}
.home-four-binduz-popular-post .binduz-er-thumb {
  width: 100%;
  height: 100%;
}
.social-news-slider-gap {
  padding: 0 15px;
}
.binduz-er-social-news-box .binduz-er-social-news-slide .col-lg-4 {
  margin-left: 0px;
  margin-right: 0px;
}
.binduz-er-social-news-slide {
  position: relative;
}
.binduz-er-author-item {
  overflow: hidden;
}
.binduz-er-video-post.binduz-er-recently-viewed-item {
  padding: 0 10px;
}
.my-select-box.mexuvo-binduz-header-dropdown .my-select-box-btn {
  background: none;
  border: none;
  font-size: 14px;
}
.my-select-box.mexuvo-binduz-tech-dropdown .my-select-box-btn {
  background: none;
  width: 100%;
  border: 2px solid #d8d8d8;
  font-size: 14px;
  color: #ababab;
  box-shadow: 0 32px 32px 0 rgb(0 0 0 / 4%);
}
.binduz-er-blog-post-form .binduz-er-input-box button.my-select-box-btn:hover {
  background-color: transparent;
  color: #ababab;
}

.binduz-er-top-header-area-4 .binduz-er-top-header-lang {
  padding-top: 0;
}
.overflow-style-none::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.overflow-style-none {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.binduz-er-news-menu-item-has-children {
  transition: all 0.5s ease-out;
}
.binduz-er-news-menu-item-has-children ul {
  transition: all 0.5s ease-in;
}
.binduz-er-contact-us-area .binduz-er-input-box.binduz-er-select-item {
  margin: -10px 0 0 0;
}
.binduz-er-contact-us-area .binduz-er-input-box.binduz-er-select-item button {
  border-radius: 0;
}
.binduz-er-featured-slider-item {
  position: relative;
}
.home-ten-hero .binduz-er-editors-pack-title {
  display: none;
}
.binduz-er-video-post.binduz-er-recently-viewed-item {
  /* padding: 30px; */
}

@charset "utf-8";
/* CSS Document */
* {
  font-family: "Outfit", sans-serif;
  margin: 0;
  padding: 0;
}
.banner_bg {
  width: 100%;
  background: linear-gradient(
    113deg,
    rgba(255, 104, 32, 0.9) -1.21%,
    rgba(12, 10, 87, 0.9) 52.28%,
    rgba(4, 106, 56, 0.9) 87.35%
  );
  padding: 0 0 3em;
  display: block;
}
.banner_bg img {
  width: auto;
  height: auto;
  max-width: 100%;
  display: block;
}
.banner_bg h1 {
  font-size: 60px;
  font-weight: bold;
  color: #fff;
  padding-top: 0.5em;
  display: block;
}
.banner_bg h1 span {
  font-size: 40px;
  font-weight: 300;
  color: #fff;
  display: block;
}
.navbar {
  background: linear-gradient(
    144deg,
    rgb(238 112 59) 6.79%,
    rgb(35 38 89) 63.28%,
    rgb(29 111 79) 99.35%
  );
  padding: 0.5rem 1rem;
  position: fixed !important;
}
.nav_ralign {
  justify-content: end;
}
.nav a {
  color: #ffffff;
}
.nav a:hover {
  color: #ff6820;
}
.nav a.active {
  color: #ff6820;
}
.btn_signin {
  border: #fff 2px solid;
  border-radius: 50px;
  padding: 0.5em 1.5em;
  color: #fff;
}

.ods_pd0 {
  padding: 0;
}

.bg_gray {
  width: 100%;
  height: auto;
  background: #f4f4fe;
  padding: 4em 0;
  display: block;
}
.bg_drackgray {
  width: 100%;
  height: auto;
  background: #e9e9f6;
  padding: 4em 0;
  display: block;
}
.bg_white {
  width: 100%;
  height: auto;
  background: #ffffff;
  padding: 4em 0;
  display: block;
}
.bg_222 {
  width: 100%;
  height: auto;
  background: #222222;
  padding: 2em 0;
  display: block;
}

.pt-2 {
  padding-top: 2em;
}
.pt-3 {
  padding-top: 3em;
}
.pt-4 {
  padding-top: 4em;
}
.pt-5 {
  padding-top: 5em;
}
.pt-6 {
  padding-top: 6em;
}
.pt-7 {
  padding-top: 7em;
}
.pt-8 {
  padding-top: 8em;
}
.pt-9 {
  padding-top: 9em;
}

#custom-search {
  margin-right: -30px;
  display: inline-block;
}
#custom-search .search-query {
  padding: 5px 34px 5px 15px;
  width: 50px;
  height: auto;
  border: 2px solid transparent;
  background-color: transparent;
  color: transparent;
  font-size: 16px;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
  margin-bottom: 0;
}
#custom-search .search-query::placeholder {
  color: transparent;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
}
#custom-search .search-query::-webkit-input-placeholder {
  color: transparent;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
}
#custom-search .search-query::-moz-placeholder {
  color: transparent;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
}
#custom-search .search-query:-moz-placeholder {
  color: transparent;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
}
#custom-search .search-query:-ms-input-placeholder {
  color: transparent;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
}
#custom-search .search-query:focus {
  border-radius: 50px;
  border: 2px solid #fff;
  width: 200px;
  color: #fff;
  outline: none;
}
#custom-search .search-query:focus::placeholder {
  color: #fff;
}
#custom-search .search-query:focus::-webkit-input-placeholder {
  color: #fff;
}
#custom-search .search-query:focus::-moz-placeholder {
  color: transparent;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
}
#custom-search .search-query:focus::-moz-placeholder {
  color: transparent;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
}
#custom-search .search-query:focus:-ms-input-placeholder {
  color: #fff;
}
#custom-search button {
  border: 0;
  background: none;
  padding: 0;
  width: 50px;
  margin-top: -5px;
  position: relative;
  top: -1px;
  left: -45px;
  margin-bottom: 0;
  border-radius: 3px;
}
#custom-search button i {
  margin-left: -13px;
  color: #fff;
}

.mySlides {
  display: none;
}
img {
  vertical-align: middle;
}
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}
.dot {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: #fff;
  border-radius: 10px;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.dot_active {
  background-color: #ff6820;
  width: 20px;
}
.fade {
  animation-name: fade;
  animation-duration: 2.5s;
}
@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
@media only screen and (max-width: 300px) {
  .text {
    font-size: 11px;
  }
}

.slide-v {
  width: 100%;
  margin: 0 auto;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.slide {
  padding: 0;
  color: white;
  text-align: right;
  font-size: 16px;
}
.slide img {
  width: auto;
  height: auto;
  max-width: 100%;
  border: none;
  display: block;
}
.slide p {
  font-size: 15px;
  color: #fff;
  text-align: left;
  padding: 0.5em 0 0;
  margin: 0;
  display: block;
}
.slide span {
  font-size: 12px;
  color: #fff;
  text-align: left;
  padding: 0;
  display: block;
}
.slide span i {
  padding-right: 3px;
}

.button-container {
  padding-top: 0;
  width: 100%;
  margin: -2.4em auto 0;
  border: none;
  position: relative;
  z-index: 99;
}
.button-container button {
  background: #fff;
  border: none;
  padding: 2px 8px;
  margin-left: 5px;
  float: right;
}

.mc_main {
  display: block;
}
.mc_main h2 {
  font-size: 42px;
  font-weight: 600;
  color: #000000;
  border-left: #ff6820 5px solid;
  padding: 0 0 0 0.3em;
  display: block;
}
.search_box_01 {
  background: #ffffff;
  padding: 0.5em 1em;
  float: right;
}
.search_box_01 i {
  margin: 4px 0;
  color: #222222;
  z-index: 99;
  position: relative;
  float: right;
}
.search_box_01 input {
  background: none;
  border: none;
  outline: none;
  font-size: 16px;
  margin-right: 5px;
  color: #222222;
  float: right;
}

.mc_card {
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  min-height: 325px;
  display: block;
}
.mc_card img {
  width: auto;
  height: auto;
  max-width: 100%;
  border: none;
  filter: grayscale(100%);
  display: block;
}
.mc_card span {
  width: 110px;
  margin: -2.2em 0 0;
  position: relative;
  z-index: 9;
  font-size: 14px;
  color: #ffffff;
  background: #ff6820;
  padding: 5px 10px;
  text-align: center;
  float: left;
}
.mc_card p {
  font-size: 14px;
  color: #000000;
  padding: 0.8em;
  margin: 0;
  display: block;
}
.mc_card p b {
  font-size: 16px;
  color: #000000;
  font-weight: bold;
  padding-bottom: 0.3em;
  display: block;
}
.mc_card p a {
  font-size: 14px;
  color: #ff6820;
  font-weight: 500;
  text-decoration: none;
  text-align: right;
  padding-top: 1em;
  float: right;
}
.btn_seeall {
  width: 150px;
  border: #000000 2px solid;
  border-radius: 50px;
  text-align: center;
  font-weight: 500;
  color: #000000;
  font-size: 16px;
  text-decoration: none;
  padding: 0.5em 1.5em;
  margin: 3em auto 0;
  display: block;
}
.btn_seeall i {
  color: #ff6820;
  padding-left: 2em;
}

.btn_post {
  width: 130px;
  background: #222222;
  border: none;
  border-radius: 50px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  padding: 0.5em 1.5em;
  margin: 0;
  float: right;
}
.btn_post i {
  color: #fff;
  padding-left: 1em;
}

.btn_post_event {
  width: auto;
  background: #222222;
  border: none;
  border-radius: 50px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  padding: 0.5em 1.2em;
  margin: 0;
  float: right;
}
.btn_post_event i {
  color: #fff;
  padding-left: 0.5em;
}

.btn_event_cal {
  width: auto;
  background: #ffffff;
  border: #000000 2px solid;
  border-radius: 50px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
  font-size: 16px;
  text-decoration: none;
  padding: 0.4em 1.2em;
  margin: 0 1em 0 0;
  float: right;
}
.btn_event_cal i {
  color: #ff6820;
  padding-left: 0.5em;
}

.scroll-container {
  display: flex;
  align-items: center;
  position: relative;
}
.scrol_container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  white-space: nowrap;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.scrol_container::-webkit-scrollbar {
  display: none;
}
.ad_card {
  flex: 0 0 auto;
  margin-right: 20px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;
  position: relative;
  overflow: hidden;
}
.ad_card:hover {
  transform: scale(1.05);
}
.scroll-btn {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
}
.scroll-btn.left {
  left: 0;
}
.scroll-btn.right {
  right: 0;
}

.event_scroll {
  display: -webkit-inline-box;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.event_scroll::-webkit-scrollbar {
  display: none;
}

.event_main {
  display: block;
}
.event_main h2 {
  font-size: 42px;
  font-weight: 600;
  color: #000000;
  border-left: #ff6820 5px solid;
  padding: 0 0 0 0.3em;
  display: block;
}
.event_main img {
  width: auto;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

.h_event_card {
  background: #f4f4fe;
  padding-bottom: 1em;
  transition: transform 0.2s;
  display: block;
}
.h_event_card img {
  width: auto;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
.h_event_card span {
  font-size: 11px;
  color: #999;
  text-align: left;
  font-weight: normal;
  padding: 1em 1em 0;
  display: block;
}
.h_event_card span i {
  padding-right: 5px;
}
.h_event_card p {
  font-size: 12px;
  color: #000;
  text-align: left;
  font-weight: normal;
  padding: 0 1em;
  margin: 0;
  min-height: 96px;
  display: block;
}
.h_event_card p b {
  font-size: 12px;
  color: #000;
  text-align: left;
  font-weight: bold;
  padding: 0.5em 0 0;
  display: block;
}
.h_event_card a {
  font-size: 12px;
  color: #ff6820;
  text-align: right;
  font-weight: normal;
  padding: 0 1em;
  clear: both;
  display: block;
}
.h_event_card:hover {
  transform: scale(1.1);
}

.event_card {
  background: #f4f4fe;
  padding-bottom: 1em;
  transition: transform 0.2s;
  margin-bottom: 2em;
  display: block;
}
.event_card img {
  width: auto;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
.event_card span {
  font-size: 12px;
  color: #999;
  text-align: left;
  font-weight: normal;
  padding: 1em 1em 0;
  display: block;
}
.event_card span i {
  padding-right: 5px;
}
.event_card p {
  font-size: 14px;
  color: #000;
  text-align: left;
  font-weight: normal;
  padding: 0 1em;
  margin: 0;
  min-height: 96px;
  display: block;
}
.event_card p b {
  font-size: 14px;
  color: #000;
  text-align: left;
  font-weight: bold;
  padding: 0.5em 0 0;
  display: block;
}
.event_card a {
  font-size: 14px;
  color: #ff6820;
  text-align: right;
  font-weight: normal;
  padding: 0 1em;
  clear: both;
  display: block;
}
.event_card:hover {
  transform: scale(1.1);
}

.event_inner_banner {
  background: #ffefe8;
  max-height: 426px;
  overflow: hidden;
  transition: all 0.3s;
  padding-top: 4em;
  display: block;
}
.event_inner_banner img {
  width: auto;
  height: auto;
  max-width: 70%;
  transform: rotate(346deg);
}
.event_inner_banner h1 {
  font-size: 52px;
  color: #000000;
  font-weight: bold;
  text-shadow: 3px 4px rgba(0, 0, 0, 0.07);
  padding: 1.8em 0;
  display: block;
}

.cd_detales {
  margin: 0 0 1em;
  min-height: 109px;
}
.cd_detales label {
  font-size: 14px;
  font-weight: normal;
  text-decoration: none;
  color: #fff;
  text-align: left;
  padding: 0;
  display: inline-block;
}
.cd_detales label b {
  font-size: 18px;
  display: block;
}
.cd_detales a {
  text-decoration: none;
}
.cd_detales img {
  width: auto;
  height: auto;
  max-width: 100%;
  border: none;
  margin: 0;
  padding: 0 1em 0 0;
  display: inline-block;
}
.cd_tab1 {
  background: #2e94d3;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 1em;
  display: flex;
}
.cd_tab2 {
  background: #91b397;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 1em;
  display: flex;
}
.cd_tab3 {
  background: #ca7b3f;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 1em;
  display: flex;
}
.cd_tab4 {
  background: #cf7368;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 1em;
  display: flex;
}
.cd_tab5 {
  background: #917d7d;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 1em;
  display: flex;
}
.cd_mtb {
  margin: 0.9em 0;
}

.ed_box {
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 1em;
  display: block;
}
.ed_box h4 {
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  padding-right: 2em;
  display: block;
}
.ed_box p {
  font-size: 18px;
  color: #000000;
  font-weight: normal;
  padding-right: 2em;
  display: block;
}
.ed_box img {
  width: auto;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
.ed_box p img {
  display: inline-block;
  margin: 0 5px 5px 0;
  height: 70px;
  border-radius: 5px;
}
.ed_rimg {
  transform: rotate(352deg);
  margin: 2em 0em 0em -2em !important;
}

.job_card {
  /* background: #ffffff url(../img/jobcard_bg.png) no-repeat bottom left; */
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  padding-bottom: 0.6em;
  display: block;
}
.jobs_main ul li {
  list-style-type: none;
}
.job_card label {
  font-size: 12px;
  color: #999;
  text-align: left;
  font-weight: normal;
  padding: 1em 1em 0;
  display: block;
}
.job_card label i {
  padding-right: 5px;
}
.job_card p {
  font-size: 13px;
  color: #000;
  text-align: left;
  font-weight: normal;
  padding: 0 1em;
  margin: 0;
  display: block;
}
.job_card a {
  font-size: 13px;
  color: #ff6820;
  text-align: right;
  font-weight: normal;
  padding: 0 1em;
  clear: both;
  display: block;
}
.jobs_main h4 {
  font-size: 42px;
  color: #ff6820;
  font-weight: bold;
  padding-bottom: 1em;
  display: block;
}
.jobs_main h4 span {
  font-size: 26px;
  color: #222222;
  font-weight: normal;
  display: block;
}
.jobs_main h2 {
  font-size: 42px;
  font-weight: 600;
  color: #000000;
  border-left: #ff6820 5px solid;
  padding: 0 0 0 0.3em;
  display: block;
}

.doctors_main {
  display: block;
}
.doctors_main h2 {
  font-size: 42px;
  font-weight: 600;
  color: #000000;
  border-left: #ff6820 5px solid;
  padding: 0 0 0 0.3em;
  display: block;
}
.doctor_photo {
  display: block;
  margin: 0 0 1.5em;
}
.doctor_photo h5 {
  position: absolute;
  bottom: 0.5em;
  right: 0.5em;
  background: #fff;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 0.3em 0.8em;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  display: block;
}
.doctor_photo h5 span {
  font-size: 12px;
  color: #ff6820;
  font-weight: normal;
  display: block;
}
.doctor_photo img {
  width: auto;
  height: auto;
  max-width: 100%;
  border: none;
  margin: 0 auto;
  display: block;
}

.prime_listings h2 {
  font-size: 42px;
  font-weight: 600;
  color: #000000;
  border-left: #ff6820 5px solid;
  padding: 0 0 0 0.3em;
  display: block;
}
.prime_star {
  width: auto;
  height: auto;
  max-width: 100%;
  position: absolute;
  z-index: 99;
  top: 0;
  right: 1.5em;
  float: right;
}

.prime_card {
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  min-height: 325px;
  display: block;
}
.prime_card img {
  width: auto;
  height: auto;
  max-width: 100%;
  border: none;
  display: block;
}
.prime_card span {
  width: 110px;
  margin: -2.2em 0 0;
  position: relative;
  z-index: 9;
  font-size: 14px;
  color: #ffffff;
  background: #ff6820;
  padding: 5px 10px;
  text-align: center;
  float: left;
}
.prime_card p {
  font-size: 13px;
  color: #767e94;
  padding: 0.8em;
  margin: 0;
  display: block;
}
.prime_card p b {
  font-size: 14px;
  color: #191f33;
  font-weight: bold;
  padding-bottom: 0.3em;
  display: block;
}
.prime_card a {
  font-size: 14px;
  color: #ff6820;
  font-weight: 500;
  text-decoration: none;
  text-align: right;
  padding: 0.8em;
  float: right;
}
.prime_card label {
  font-size: 12px;
  color: #999;
  text-align: left;
  padding: 0.8em;
  display: block;
}
.prime_card label i {
  padding-right: 3px;
}

.lc_card {
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 2em;
  display: block;
}
.lc_card img {
  width: auto;
  height: auto;
  max-width: 100%;
  border: none;
  display: block;
}
.lc_card span {
  width: 110px;
  margin: -2.2em 0 0;
  position: relative;
  z-index: 9;
  font-size: 14px;
  color: #ffffff;
  background: #ff6820;
  padding: 5px 10px;
  text-align: center;
  float: left;
}
.lc_card p {
  font-size: 13px;
  color: #767e94;
  padding: 0.8em;
  margin: 0;
  display: block;
}
.lc_card p b {
  font-size: 14px;
  color: #191f33;
  font-weight: bold;
  padding-bottom: 0.3em;
  display: block;
}
.lc_card a {
  font-size: 14px;
  color: #ff6820;
  font-weight: 500;
  text-decoration: none;
  text-align: right;
  padding: 0.8em;
  float: right;
}
.lc_card label {
  font-size: 12px;
  color: #999;
  text-align: left;
  padding: 0.8em;
  display: block;
}
.lc_card label i {
  padding-right: 3px;
}

.classifieds_main h2 {
  font-size: 42px;
  font-weight: 600;
  color: #000000;
  border-left: #ff6820 5px solid;
  padding: 0 0 0 0.3em;
  display: block;
}
.classfi_icon {
  width: 100%;
  height: auto;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  padding: 1em;
  margin: 0 0 1.8em;
  display: block;
}
.classfi_icon:hover {
  transform: scale(1.1);
}
.classfi_icon span {
  width: 100%;
  font-size: 16px;
  text-decoration: none;
  color: #fff;
  text-align: center;
  display: block;
}
.classfi_icon img {
  width: auto;
  height: auto;
  max-width: 100%;
  border: none;
  margin: 0 auto 1em;
  display: block;
}
.cf_icon1 {
  background: #167d6c;
}
.cf_icon2 {
  background: #023f80;
}
.cf_icon3 {
  background: #d8209a;
}
.cf_icon4 {
  background: #78496b;
}
.cf_icon5 {
  background: #9668e4;
}
.cf_icon6 {
  background: #f78026;
}
.cf_icon7 {
  background: #77a22f;
}
.cf_icon8 {
  background: #9d7e4f;
}
.cf_icon9 {
  background: #f48886;
}
.cf_icon10 {
  background: #454545;
}
.cf_icon11 {
  background: #3572dd;
}
.cf_icon22 {
  background: #f1ba46;
}
.cf_icon22 span {
  color: #000 !important;
}

.ad_card1 {
  margin-right: 20px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;
  display: inline-block;
}
.ad_card1:hover {
  transform: scale(1.05);
}

.podcast_main {
  display: block;
}
.podcast_main h2 {
  font-size: 42px;
  font-weight: 600;
  color: #000000;
  text-align: center;
  padding: 0 0 0 0.3em;
  display: block;
}
.podcast_main h2::after {
  width: 50px;
  border-bottom: #ff6820 5px solid;
  content: "";
  margin: 0.2em auto;
  display: block;
}

.podcast_card {
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  padding-bottom: 1em;
  transition: transform 0.2s;
  display: block;
}
.podcast_card iframe {
  width: 100%;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
.podcast_card label {
  background: #ff6820;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  position: relative;
  z-index: 99;
  margin-top: -1.9em;
  padding: 0.2em 1em;
  float: left;
}
.podcast_card span {
  font-size: 12px;
  color: #999;
  text-align: left;
  font-weight: normal;
  padding: 1em 1em 0;
  display: block;
}
.podcast_card span i {
  padding-right: 5px;
}
.podcast_card p {
  font-size: 16px;
  color: #000;
  text-align: left;
  font-weight: normal;
  padding: 0 1em;
  margin: 0;
  display: block;
}
.podcast_card p b {
  font-size: 16px;
  color: #000;
  text-align: left;
  font-weight: bold;
  padding: 0.5em 0 0;
  display: block;
}

.podcast_scroll {
  display: -webkit-inline-box;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.podcast_scroll::-webkit-scrollbar {
  display: none;
}

.classified_sc label {
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  margin: 0;
  padding: 0.8em 0;
}
.classified_sc select {
  width: 100%;
  background: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 0;
  font-size: 14px;
  padding: 0.7em 2.5em 0.7em 0.8em;
  margin: 0 0 1em;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.classified_sc select.category {
  background-image: linear-gradient(45deg, transparent 50%, rgb(0, 0, 0) 50%),
    linear-gradient(135deg, rgb(0, 0, 0) 50%, transparent 50%),
    linear-gradient(to right, rgb(255, 255, 255), rgb(255, 255, 255));
  background-position: calc(100% - 19px) calc(1.2em + 2px),
    calc(100% - 15px) calc(1.2em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}
.classified_sc select.category:focus {
  background-image: linear-gradient(45deg, rgb(0, 0, 0) 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, rgb(0, 0, 0) 50%),
    linear-gradient(to right, rgb(255, 255, 255), rgb(255, 255, 255));
  background-position: calc(100% - 15px) 1.2em, calc(100% - 19px) 1.2em, 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border: none;
  outline: 0;
}

.clasifid_banner {
  width: 100%;
  height: auto;
  padding: 1em 0 0;
  display: block;
}
.clasifid_banner img {
  width: 100%;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
.cl_pera p {
  font-size: 18px;
  color: #000000;
  font-weight: normal;
  display: block;
}
.cl_pera span {
  font-size: 18px;
  color: #666666;
  display: block;
}

.doctors_banner {
  width: 100%;
  height: auto;
  padding: 1em 0 0;
  display: block;
}
.doctors_banner img {
  width: 100%;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
.doct_categ {
  width: 100%;
  height: 100%;
  background: #f5f5fe;
  padding: 1em;
  display: block;
}
.doct_categ h2 {
  font-size: 32px;
  font-weight: 600;
  color: #000000;
  border-left: #ff6820 5px solid;
  padding: 0 0 0 0.3em;
  display: block;
}
.doct_categ a {
  background: #ffffff;
  font-size: 16px;
  color: #000000;
  text-decoration: none;
  font-weight: normal;
  padding: 1em;
  margin: 0.5em 0;
  display: block;
}
.doct_categ a span {
  background: #f5f5fe;
  font-size: 14px;
  color: #000000;
  padding: 0.5em 1em;
  margin: -0.35em 0;
  float: right;
}
.doct_categ a:hover {
  background: #225159;
  font-size: 16px;
  color: #ffffff !important;
  text-decoration: none;
  font-weight: normal;
  padding: 1em;
  margin: 0.5em 0;
  display: block;
}
.doct_categ a:hover span {
  background: #184148;
  font-size: 14px;
  color: #ffffff;
  padding: 0.5em 1em;
  margin: -0.35em 0;
  float: right;
}
.doct_categ a.active {
  background: #225159;
  font-size: 16px;
  color: #ffffff !important;
  text-decoration: none;
  font-weight: normal;
  padding: 1em;
  margin: 0.5em 0;
  display: block;
}
.doct_categ a.active span {
  background: #184148;
  font-size: 14px;
  color: #ffffff;
  padding: 0.5em 1em;
  margin: -0.35em 0;
  float: right;
}

.doct_categ .dc_scroll {
  max-height: 700px;
  overflow-y: auto;
}
.doct_categ .dc_scroll::-webkit-scrollbar {
  width: 10px;
}
.doct_categ .dc_scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.doct_categ .dc_scroll::-webkit-scrollbar-thumb {
  background: #888;
}
.doct_categ .dc_scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.doct_main h2 {
  font-size: 36px;
  font-weight: 600;
  color: #000000;
  border-left: #ff6820 5px solid;
  padding: 0 0 0 0.3em;
  display: block;
}
.doct_main h2 span {
  font-weight: normal;
}
.doct_main .s_box {
  background: #f5f5fe;
  padding: 0.5em 1em;
  float: right;
}
.doct_main .s_box i {
  margin: 4px 0;
  color: #222222;
  z-index: 99;
  position: relative;
  float: right;
}
.doct_main .s_box input {
  background: none;
  border: none;
  outline: none;
  font-size: 16px;
  margin-right: 5px;
  color: #222222;
  float: right;
}
.doct_main p {
  font-size: 16px;
  color: #000000;
  font-weight: normal;
  display: block;
}

.doct_photo {
  display: block;
  margin: 0 0 1.5em;
}
.doct_photo h5 {
  position: absolute;
  bottom: 0.5em;
  right: 0.5em;
  background: #fff;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 0.3em 0.8em;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  display: block;
}
.doct_photo h5 span {
  font-size: 12px;
  color: #ff6820;
  font-weight: normal;
  display: block;
}
.doct_photo img {
  width: auto;
  height: auto;
  max-width: 100%;
  border: none;
  margin: 0 auto;
  display: block;
}

.doct_name {
  width: 100%;
  display: block;
  margin: 0 0 1.5em;
}
.doct_name h5 {
  background: #fff;
  box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.1);
  padding: 0.3em 0.8em;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  display: block;
}
.doct_name h5 span {
  font-size: 12px;
  color: #ff6820;
  font-weight: normal;
  display: block;
}

.btn_share {
  width: 130px;
  border: #000000 2px solid;
  border-radius: 50px;
  text-align: center;
  font-weight: 500;
  color: #000000;
  font-size: 16px;
  text-decoration: none;
  padding: 0.5em 1.5em;
  margin: 2em auto 0;
  display: inline-block;
}
.btn_share i {
  color: #ff6820;
  padding-right: 1em;
}

.search_box_02 {
  background: #ffffff;
  padding: 0.5em 1em;
  display: block;
}
.search_box_02 i {
  margin: 4px 0;
  color: #222222;
  z-index: 99;
  position: relative;
  float: right;
}
.search_box_02 input {
  background: none;
  border: none;
  outline: none;
  font-size: 16px;
  margin-right: 5px;
  color: #222222;
  display: block;
}

.business_card {
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  min-height: 325px;
  margin-bottom: 2em;
  display: block;
}
.business_card img {
  width: auto;
  height: auto;
  max-width: 100%;
  border: none;
  display: block;
}
.business_card span {
  width: 110px;
  margin: -2.2em 0 0;
  position: relative;
  z-index: 9;
  font-size: 14px;
  color: #ffffff;
  background: #ff6820;
  padding: 5px 10px;
  text-align: center;
  float: left;
}
.business_card h4 {
  font-size: 24px;
  color: #191f33;
  font-weight: bold;
  padding: 0.8em 0.8em 0.3em 0.8em;
  display: block;
}
.business_card p {
  min-height: 70px;
  font-size: 13px;
  color: #767e94;
  padding: 0.8em 0.8em 0 0.8em;
  margin: 0;
  display: block;
}
.business_card b {
  font-size: 14px;
  color: #191f33;
  font-weight: bold;
  padding: 0.8em 0.8em 0.3em 0.8em;
  display: block;
}
.business_card a {
  font-size: 14px;
  color: #ff6820;
  font-weight: 500;
  text-decoration: none;
  text-align: right;
  padding: 0.8em;
  float: right;
}
.business_card label {
  font-size: 12px;
  color: #999;
  text-align: left;
  padding: 0 0.8em;
  margin: 0;
  display: block;
}
.business_card label i {
  padding-right: 3px;
}

.business_details {
  width: 100%;
  margin-bottom: 2em;
  display: block;
}
.business_details img {
  width: auto;
  height: auto;
  max-width: 100%;
  border: none;
  display: block;
}
.business_details h4 {
  font-size: 24px;
  color: #191f33;
  font-weight: bold;
  padding: 0.8em 0 0;
  display: block;
}
.business_details p {
  font-size: 16px;
  color: #222222;
  padding: 0.8em 0 0 0;
  margin: 0;
  display: block;
}
.business_details label {
  font-size: 14px;
  color: #999;
  text-align: left;
  padding: 0;
  margin: 0;
  display: block;
}
.business_details label i {
  padding-right: 3px;
}

.b-pagination-outer {
  width: 100%;
  margin: 1em auto 0;
  text-align: center;
  overflow: hidden;
  display: flex;
}
#border-pagination {
  margin: 0 auto;
  padding: 0;
  text-align: center;
}
#border-pagination li {
  display: inline;
}
#border-pagination li a {
  width: auto;
  display: block;
  text-decoration: none;
  color: #000;
  background: #ffffff;
  padding: 0.5em 1em;
  margin: 0 0.2em;
  border: none;
  float: left;
}
#border-pagination li a {
  -webkit-transition: background-color 0.4s;
  transition: background-color 0.4s;
}
#border-pagination li a.active {
  background-color: #ff6820;
  color: #fff;
}
#border-pagination li a:hover:not(.active) {
  background-color: #ff6820;
  color: #fff;
}

.calendar_filter {
  height: 100%;
  background: #454545;
  padding: 1em;
}
.calendar_filter h3 {
  font-size: 30px;
  color: #fff;
  display: block;
}
.calendar_filter h3 span {
  font-size: 18px;
  color: #fff;
  display: block;
}
.calendar_filter label {
  font-size: 16px;
  color: #ffffff;
  display: block;
}
.calendar_filter input {
  width: 100%;
  background: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  padding: 0.8em;
  margin: 0 0 1em;
  display: block;
}
.calendar_filter input[type="date"] {
  width: 100% !important;
  height: auto;
  background: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  padding: 0.8em;
  margin: 0 0 1em;
  display: block;
}
.calendar_filter select {
  width: 100%;
  background: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  padding: 0.8em 2.5em 0.8em 0.8em;
  margin: 0 0 1em;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.calendar_filter select.category {
  background-image: linear-gradient(45deg, transparent 50%, rgb(0, 0, 0) 50%),
    linear-gradient(135deg, rgb(0, 0, 0) 50%, transparent 50%),
    linear-gradient(to right, rgb(255, 255, 255), rgb(255, 255, 255));
  background-position: calc(100% - 20px) calc(1.2em + 2px),
    calc(100% - 15px) calc(1.2em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}
.calendar_filter select.category:focus {
  background-image: linear-gradient(45deg, rgb(0, 0, 0) 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, rgb(0, 0, 0) 50%),
    linear-gradient(to right, rgb(255, 255, 255), rgb(255, 255, 255));
  background-position: calc(100% - 15px) 1.2em, calc(100% - 20px) 1.2em, 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border: none;
  outline: 0;
}

#calendar {
  width: 100%;
}
.fc-header-title h2 {
  border: none;
  padding: 0;
  font-size: 20px;
  display: inline-block;
}
/* ------- Agenda View ------- */
#agendaView > table {
  width: 100%;
  min-height: 500px;
  background: #fff;
}

#agendaView .fc-agendaView-event-start,
#agendaView .fc-agendaView-event-end {
  width: 20%;
}

#agendaView .fc-agendaView-event-title {
  width: 60%;
}
#agendaView th {
  height: 30px;
  font-size: 14px;
  vertical-align: text-bottom;
  font-weight: bold;
  padding: 10px 0 0 10px;
}

#agendaView td {
  height: 30px;
  padding: 10px 0 0 10px;
  font-size: 14px;
  vertical-align: text-bottom;
}

.marquee-wrapper {
  text-align: center;
  overflow-x: hidden;
}
.marquee-wrapper .container {
  overflow: hidden;
}
.marquee-inner span {
  float: left;
  width: 50%;
}
.marquee-wrapper .marquee-block {
  --total-marquee-items: 5;
  width: 100%;
  height: 130px;
  width: calc(300px * (var(--total-marquee-items)));
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}
.marquee-inner {
  display: block;
  width: 300%;
  position: absolute;
}
.marquee-inner.to-left {
  animation: marqueeLeft 25s linear infinite;
}
.marquee-inner.to-right {
  animation: marqueeRight 25s linear infinite;
}
.ad_card {
  display: inline-block;
  float: left;
  transition: all 0.2s ease-out;
}
@keyframes marqueeLeft {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
@keyframes marqueeRight {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}

section#slider {
  width: 100%;
}

section#slider span.nowrap {
  white-space: nowrap;
}

section#slider .slider-wrapper {
  padding: 0;
}

section#slider .scroll-wrapper {
  width: 100%;
  overflow-x: hidden;
  min-height: 195px;
}

section#slider ul {
  padding: 0;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 0;
}

section#slider li.slider {
  display: inline-block;
  position: relative;
}

section#slider span.slider-name,
span.slider-title {
  display: block;
  position: absolute;
  color: #fff;
  left: 2vw;
}

section#slider span.slider-name {
  font-weight: 900;
  bottom: 4vw;
  font-size: 1.5vw;
}

section#slider span.slider-title {
  font-weight: 100;
  bottom: 2.5vw;
  font-size: 1.3vw;
}

section#slider .more-slider {
  padding: 1vh 5% 0;
}

section#slider .more-slider h4 {
  font-family: "Playfair Display", serif;
  color: #5896b0;
  font-weight: 500;
  font-size: 3vw;
  padding: 35px 0 25px 0px;
  margin-bottom: 0;
  margin-top: 8px;
}

section#slider .more-slider p {
  padding-bottom: 2vh;
}

section#slider .slide-controls {
  color: #000;
  font-weight: 300;
}

section#slider .slide-controls span.slider-previous {
  margin-right: 15px;
  cursor: pointer;
}
section#slider .slide-controls span.slider-next {
  cursor: pointer;
}

section#slider .slide-controls i {
  font-size: 16px;
  background: #fff;
  padding: 0.5em 0.8em;
}

section#slider .more-slider a.button {
  border: 1px solid #5896b0;
  max-width: 200px;
  color: #5896b0;
  margin-top: 6vw;
}

section#slider .more-slider a.button:hover {
  color: #fff;
}

section#slider .more-slider a.button:before {
  background: #5896b0;
}

/*** Media Queries ***/

@media (max-width: 991px) {
  section#slider .more-slider {
    padding: 1vh 3% 0;
  }

  section#slider .more-slider h4 {
    font-size: 5vw;
    padding: 0 0 15px 0;
  }

  section#slider .slider-wrapper {
    width: 50%;
  }

  section#slider .content {
    padding: 35px;
  }

  section#slider .more-slider a.button {
    margin-top: 4vw;
    max-width: 175px;
    font-size: 15px;
    padding: 5px 0;
  }
}

@media (max-width: 768px) {
  section#slider .area {
    padding-right: 0;
    padding-left: 0;
  }

  section#slider .slider-wrapper {
    width: 100%;
  }

  section#slider .scroll-wrapper {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }

  section#slider li.slider {
    width: 49.2vw;
  }

  section#slider span.slider-title {
    bottom: 3.5vw;
    font-size: 3.3vw;
  }

  section#slider span.slider-name {
    bottom: 7vw;
    font-size: 3.5vw;
  }

  section#slider .more-slider h4 br,
  section#slider .slide-controls {
    display: none;
  }

  section#slider .more-slider a.button {
    margin-top: 0;
  }

  section#slider {
    padding-bottom: 0;
    margin-left: 0;
  }

  section#slider .more-slider {
    padding-left: 0;
  }

  section#slider .more-slider h4 {
    font-size: 7vw;
  }
}

footer {
  width: 100%;
  height: auto;
  background: rgba(22, 22, 22, 0.9);
  padding: 2em 0;
  display: block;
}
footer h2 {
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  border-left: #ff6820 5px solid;
  padding: 0 0 0 0.3em;
  margin-bottom: 1em;
  display: block;
}
footer ul {
  width: 48%;
  padding: 0;
  list-style-type: none;
  display: inline-grid;
}
footer ul li {
  font-size: 16px;
  list-style-type: none;
  display: block;
}
footer ul li a {
  text-decoration: none;
  color: #fff;
  font-weight: normal;
  padding: 0.5em 0;
  display: block;
}
footer ul li a.active {
  color: #ff6820;
  background: none;
}
footer img {
  width: auto;
  height: auto;
  max-width: 100%;
  border: none;
  margin: 0 auto;
  display: block;
}
footer p {
  font-size: 16px;
  color: #ffffff;
  font-weight: normal;
  padding: 0;
  display: block;
}
.azflogo {
  width: auto;
  height: auto;
  max-width: 100%;
  border: none;
  margin: 0 0 1em;
  display: block;
}
footer input {
  width: 100%;
  background: #fff;
  border: none;
  outline: none;
  border-radius: 0;
  font-size: 14px;
  padding: 0.5em 1em 0.5em 2.2em;
  margin: 0 0 1em;
  display: block;
}
footer button {
  width: 100%;
  background: #ff6820;
  border: none;
  color: #ffffff;
  border-radius: 0;
  text-align: center;
  padding: 0.5em 0;
  display: block;
}
footer .test_field i {
  color: #999;
  position: absolute;
  z-index: 99;
  padding: 9.5px;
}

.copyright {
  background: #333333;
  padding: 1.5em 0;
  display: block;
  clear: both;
}
.copyright p {
  font-size: 17px;
  color: #fff;
  text-decoration: none;
  font-weight: 300;
  padding: 0.25em 0;
  margin: 0;
  display: inline-block;
}
.fs_icons {
  width: 245px;
  height: auto;
  margin: 0 auto;
  float: right;
}
.fs_icons i {
  font-size: 24px;
  color: #ffffff;
  padding: 10px 15px;
  display: inline-table;
}
.fs_icons i:hover {
  color: #ff6820;
}

@media only screen and (max-width: 480px) {
  .navbar-header {
    width: 100%;
    float: left;
  }
  .a_fr {
    margin: 5px 0;
    float: right;
  }
  .navbar-nav {
    background: #fff;
  }
  .nav a {
    color: #000000;
  }
  .nav-link {
    border-bottom: #00000038 1px solid;
  }
  .dropdown-toggle::after {
    float: right;
  }
  .navbar .dropdown-menu {
    width: 100%;
  }
  .navbar .dropdown-menu a {
    border-bottom: #d6d6d6 1px solid;
  }
  #custom-search {
    margin: 1em -30px 1em 0;
  }
  .banner_bg h1 {
    font-size: 26px;
    font-weight: bold;
    color: #fff;
    padding: 0.2em 0;
    display: block;
  }
  .banner_bg h1 span {
    font-size: 20px;
    font-weight: 300;
    color: #fff;
    display: block;
  }
  .ods_pd0 {
    padding: 0 15px;
  }
  .search_box_01 {
    width: 100%;
    margin-top: 0.5em;
  }
  .mc_main h2 {
    font-size: 26px;
  }
  .search_box_01 input {
    width: 92%;
  }
  .mc_card {
    margin-bottom: 1.5em;
    min-height: auto;
    float: left;
  }
  .mc_card p {
    font-size: 16px;
  }
  .mc_card p a {
    padding: 1em 0;
    font-size: 16px;
  }
  .job_card {
    margin: 0 0 1em;
  }
  .jobs_main label {
    font-size: 12px;
  }
  .jobs_main p {
    font-size: 16px;
  }
  .jobs_main a {
    font-size: 16px;
  }
  .jobs_main h4 {
    padding-bottom: 0em;
  }
  .prime_listings h2 {
    font-size: 32px;
  }

  .prime_card {
    margin: 0 0 1.5em;
  }
  .prime_card p {
    font-size: 16px;
  }
  .prime_card p b {
    font-size: 18px;
  }
  footer .col-sm-3 {
    padding-top: 2em;
  }
  .copyright p {
    font-size: 18px;
    width: 100%;
    text-align: center;
  }
  .fs_icons {
    width: 220px;
    float: none;
    display: block;
  }

  #calendar {
    margin: 1em 0;
  }
  .btn_post_event {
    font-size: 12px;
    padding: 0.5em 1.5em;
    margin: 1em 0 0;
    float: right;
  }
  .btn_event_cal {
    font-size: 12px;
    padding: 0.37em 1.5em;
    margin: 1em 1em 0 0;
  }
  .fc-header tr td {
    display: block;
  }
  .fc-header-title {
    display: block !important;
  }

  .event_inner_banner {
    max-height: 100%;
  }
  .event_inner_banner img {
    max-width: 100%;
    transform: rotate(0deg);
  }
  .event_inner_banner h1 {
    font-size: 34px;
    padding: 1em 0;
  }
  .ed_rimg {
    display: none !important;
  }
  .doct_categ .dc_scroll {
    max-height: 300px;
  }
  .doct_main {
    margin-top: 2em;
  }

  .doct_photo h5 {
    padding: 0.5em 0.8em;
    font-size: 16px;
  }
  .doct_photo h5 span {
    font-size: 14px;
  }
  .doct_name h5 {
    padding: 0.5em 0.8em;
    font-size: 16px;
  }
  .doct_name h5 span {
    font-size: 14px;
  }

  .business_card p {
    font-size: 16px;
  }
  .business_card b {
    font-size: 16px;
  }
  .business_card a {
    font-size: 16px;
  }
  .business_card label {
    font-size: 14px;
  }
  .business_card label i {
    padding-right: 3px;
  }
}
