/* Wrapper for the entire OTP input box */
.otp-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Space between OTP inputs */
}

.otp-width{
    width: 550px;
}

/* Basic style for each OTP input */
.otp-input {
  width: 50px !important;
  height: 50px;
  margin: 5px;
  font-size: 20px;
  text-align: center;
  border: 2px solid #d8d8d8;
  border-radius: 5px;
  transition: all 0.2s ease;
}

/* Style when an OTP input is focused */
.otp-input-focus {
  border-color: #FF6821;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Optional: Add a hover effect */
.otp-input:hover {
  border-color: #FF6821;
}

/* Responsive behavior */
@media (max-width: 768px) {
  .otp-input {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }

  .otp-input-container {
    gap: 5px;
  }
}
