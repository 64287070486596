.popup-modal {
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 10000000;
  padding: 10px;
  margin-left: 90px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(45deg, #FF6821, #ffeb3b, #4caf50, #2196f3);
  animation: slowTransform 2s ease-in-out; /* Add this line */
}

@keyframes slowTransform {
  0% {
    transform: translate(-50%, -50%) scale(0.9);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
/* .popup-modal {
  position: absolute;
 
  transform: translate(-50%, -50%) scale(0.9);
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(45deg, #FF6821, #ffeb3b, #4caf50, #2196f3);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out; 
  opacity: 0; 
}

.show {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
} */

/* Add these styles to your CSS file */

.custom-event-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  height: 450px;
  overflow: scroll;
  transform: translate(-50%, -50%);
  background-color: white;
  /* border-radius: 15px; */
  padding: 20px;
  width: 450px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    45deg,
    #FF6821,
    #ffeb3b,
    #4caf50,
    #2196f3
  );
}

