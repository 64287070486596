
.tab-wrapper {
    margin-bottom: 10px;
  }
  .tab-header {
    position: relative;
    display: inline-grid;
    align-items: center;
    width: 100%;
  }
  
  .tab-header .tab-btn {
    width: 100%;
    padding: 15px 20px;
    margin: .5em 0;
    border: none;
    cursor: pointer;
    z-index: 1;
    background: #fff;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    display: block;
  }

  
  .tab-btn span{font-size: 12px; color: #000; text-align: left; font-weight: normal; display: block;}
  .tab-btn p{font-size: 16px; color: #000; text-align: left; font-weight: normal; padding: 0; margin: 0; display: block;}
  .tab-btn p b{font-size: 16px; color: #000; text-align: left; font-weight: bold; padding: .5em 0 0; display: block;}
  .tab-btn a{font-size: 16px; color: #FF6820; text-align: right; font-weight: normal; clear: both; display: block; float: right;}

  .tab-btn:hover span{font-size: 12px; color: #fff; text-align: left; font-weight: normal; display: block;}
  .tab-btn:hover p{font-size: 16px; color: #fff; text-align: left; font-weight: normal; padding: 0; margin: 0; display: block;}
  .tab-btn:hover p b{font-size: 16px; color: #fff; text-align: left; font-weight: bold; padding: .5em 0 0; display: block;}
  .tab-btn:hover a{font-size: 16px; color: #000; text-align: right; font-weight: normal; clear: both; display: block; float: right;}
  
  .tab-btn.active span{font-size: 12px; color: #fff; text-align: left; font-weight: normal; display: block;}
  .tab-btn.active p{font-size: 16px; color: #fff; text-align: left; font-weight: normal; padding: 0; margin: 0; display: block;}
  .tab-btn.active p b{font-size: 16px; color: #fff; text-align: left; font-weight: bold; padding: .5em 0 0; display: block;}
  .tab-btn.active a{font-size: 16px; color: #000; text-align: right; font-weight: normal; clear: both; display: block; float: right;}

  .tab-header .tab-btn:focus {
    outline: none;
  }
  .tab-header .tab-btn i {
    margin-right: 5px;
    font-size: 10px;
  }
  .tab-header .tab-btn:hover,
  .tab-header .tab-btn.active {
    background: #FF6820;
  }

  .tab-header .underline {
    position: absolute;
    bottom: -1px;
    left: 0;
    height: 2px;
    width: 0;
    transform-origin: left;
    transition: all 0.3s ease;
    width: auto !important;
  }
  
  .tab-body {
    display: flex;
    overflow: hidden;
  }
  .tab-body .tab-content {
    min-width: 100%;
    padding: 20px;
    font-size: 12px;
    transition: all 0.3s;
    text-align: justify;
    border: #ff6820 2px solid;
    max-height: 426px;
  }
  .tab-body .tab-content img{
    width: auto; height: auto; max-width: 100%; border: none; /*box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.10);*/ transform: rotate(346deg); padding: 2em; margin: -5px 0 0px 10px; display: block;    
  }
  
  .tab-wrapper[rounded] {
    border-radius: 10px;
  }
  .tab-header[full-width] .tab-btn {
    flex: 1;
    text-align: center;
  }
  .tab-header[background] {
    background-color: #6a00fb;
  }
  .tab-header[background] .tab-btn {
    color: #fff;
  }
  .tab-header[background] .tab-btn.active {
    color: #6a00fb;
  }
  .tab-header[background] .tab-btn.active:after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: #fff;
  }
  .tab-header[background] .underline {
    background-color: #fff;
    height: 100%;
    bottom: 0;
  }
  .tab-header[border] .tab-btn {
    border: 1px solid #e5e5e5;
  }
  .tab-header[border] .tab-btn.active {
    border: 1px solid #6a00fb;
  }
  .tab-header[border] .tab-btn.active {
    color: #6a00fb;
  }
  .tab-header[border] .underline {
    height: 100%;
    bottom: 0;
  }
  
  /* Credits */
  
  .credits {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 10px;
    font-size: 12px;
    color: #fff;
    background-color: #6a00fb;
  }
  .credits a {
    color: #fff;
    text-decoration: none;
    font-weight: 600;
  }
  .credits a:hover {
    text-decoration: underline;
  }