/* FullScreenCarousel.css */
.fullscreen-carousel-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.fullscreen-carousel-container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.open-carousel-button {
    margin: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

/* Close button (X) styles */
.close-carousel-button {
    position: absolute;
    top: 10px;
    right: 20px;
    background: none;
    border: none;
    color: white;
    font-size: 36px;
    cursor: pointer;
    z-index: 10000; /* Ensure it's above the carousel */
}

.close-carousel-button:hover {
    color: red; /* Optional hover effect */
}

.carousel .slide img {
    width: auto;
    max-height: 80vh;
    object-fit: contain;
}

@media (max-width: 768px) {
    .carousel .slide img {
        max-width: 90vw;
    }
}
