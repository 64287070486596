.movie-module {
  border-radius: 15px;
  border: 1px solid #ddd;
  margin: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transition for scaling and shadow */
}

.movie-module:hover {
  transform: scale(1.05); /* Slightly increase the size */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhance the shadow on hover */
}

.movie-module .card-title {
  font-weight: bold;
  font-size: 1.2em;
  color: black;
  transition: color 1.2s ease; /* Adding transition effect */
}

.movie-module:hover .card-title {
  color: red; /* Title color changes to red on hover */
}

.movie-module .card-text {
  font-size: 1em;
}

.movie-module .row .col-md-4 {
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .movie-module {
    padding: 10px;
  }
}
