

@keyframes borderAnimation {
  0% {
    border-image-source: linear-gradient(45deg, #FF6821, #ffeb3b, #4caf50, #2196f3);
  }
  25% {
    border-image-source: linear-gradient(135deg, #ffeb3b, #4caf50, #2196f3, #FF6821);
  }
  50% {
    border-image-source: linear-gradient(225deg, #4caf50, #2196f3, #FF6821, #ffeb3b);
  }
  75% {
    border-image-source: linear-gradient(315deg, #2196f3, #FF6821, #ffeb3b, #4caf50);
  }
  100% {
    border-image-source: linear-gradient(45deg, #FF6821, #ffeb3b, #4caf50, #2196f3);
  }
}

@keyframes modalOpen {
  from {
    opacity: 0;
    transform: translate(-50%, -60%) scale(0.8);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

@keyframes modalClose {
  from {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -60%) scale(0.8);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
}

.custom-premium-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  width: 730px;
  border: 5px solid;
  /* border-radius: 15px; */
  border-image-slice: 1;
  animation: borderAnimation 5s infinite linear;
  opacity: 0;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out, box-shadow 0.3s ease-out;
}

.custom-premium-modal-overlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.custom-premium-modal-overlay.ReactModal__Overlay--after-open {
  opacity: 1;
}

.custom-premium-modal-overlay.ReactModal__Overlay--before-close {
  opacity: 0;
}

.custom-premium-modal.ReactModal__Content--after-open {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-premium-modal.ReactModal__Content--before-close {
  opacity: 0;
  transform: translate(-50%, -60%) scale(0.8);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}
