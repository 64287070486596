.event-card {
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; */
  overflow: hidden;
  /* padding: 10px;  */
}

.event-card:hover {
  /* transform: scale(1.05); */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.event-card-img {
  width: auto; /* Maintain original width */
  height: 60px; /* Set fixed height */
  margin-right: 15px; /* Spacing between image and text */
  border-radius: 0; /* Remove the circular effect */
}

.event-card-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.event-card-footer {
  margin-top: 0.5rem;
}

@media (max-width: 768px) {
  .event-card-body {
    /* padding: 0.8rem; */
  }
}

@media (max-width: 480px) {
  .event-card-body {
    /* padding: 0.5rem; */
    flex-direction: column;
  }

  .event-card-title {
    font-size: 1rem;
  }
}
/* Slider.css */

.slider-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.slider-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
  padding: 20px;
  flex-grow: 1;
}


.custom-box {
  width: 300px;
  height: 200px;
  margin-right: 20px;
  /* border-radius: 15px; */
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  transition: transform 0.3s ease;
  overflow: hidden;
  
  /* Multicolor border */
  border: 4px solid;
  border-image: linear-gradient(45deg, red, yellow, green, blue, purple) 1;
}

.custom-box-text{
 opacity: 0;
}

.custom-box-text {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  /* border-radius: 5px; */
  opacity: 1;
  color: #fff;
  text-align: center;
}

.custom-box h3 {
  color: #fff;
  margin: 0;
  transition: color 0.3s ease;
}

.custom-box p {
  color: #fff;
  margin: 5px 0 0;
  font-size: 14px;
}

.custom-box h3 {
  color: red;
}

/* Arrows styling */
.arrow {
  font-size: 40px;
  color: white;
  cursor: pointer;
  padding: 10px;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.arrow.left {
  position: absolute;
  left: 10px;
}

.arrow.right {
  position: absolute;
  right: 10px;
}
